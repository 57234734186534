// import logo from "./logo.svg";
import "./App.css";
import React, { useEffect, useState } from "react";
import axios from "axios";


function App() {
  const [height, setHeight] = useState("750,000");

  const getHeight = () => {
    // Axios is a library that makes it easy to make http requests
    // After we make a request, we can use the .then() method to handle the response asychronously
    // This is an alternative to using async/await
    axios
      .get("https://blockstream.info/api/blocks/tip/height")
      // .then is a promise that will run when the API call is successful
      .then((res) => {
        console.log(res.data)
        setHeight(res.data.toLocaleString());
      })
      // .catch is a promise that will run if the API call fails
      .catch((err) => {
        console.log(err);
      });
  };

    // useEffect is a 'hook' or special function that will run code based on a trigger
  // The brackets hold the trigger that determines when the code inside of useEffect will run
  // Since it is empty [] that means this code will run once on page load
  useEffect(() => {
    getHeight();
  }, []);

  // Run these functions every 60 seconds after initial page load
  useEffect(() => {
    // setInterval will run whatever is in the callback function every 60 seconds
    const interval = setInterval(() => {
      getHeight();
    }, 60000);
    return () => clearInterval(interval);
  }, []);


  return (

    <div className="App">
      
     

     
        <h3>BLOCK</h3>
        <h1>{height}</h1>
   

      <footer className="App-footer">
        <img src="/AppIcon-60.png" alt="appicon-60" />
        <a
          className="App-link"
          href="https://apps.apple.com/us/app/block-screen/id1533333210"
          target="_blank"
          rel="noopener noreferrer"
        >
          Block Screen
        </a>
      </footer>

    </div>

  );
}

export default App;
